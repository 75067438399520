import { Chip, Stack, Typography } from '@mui/material';
import { useAppMode } from 'providers/AppModeProvider';
import { to12HourTime, toClockFormat } from 'utils/dateTimeUtils';

export default function AwayModeContainer() {
  const { testStartTime } = useAppMode();

  return (
    <>
      <Typography mb={2}>
        Your words are now hidden. Hope you memorised them! Come back any time after{' '}
        <b>{to12HourTime(testStartTime)}</b> to test yourself.
      </Typography>
      <Stack direction="column" alignItems="center">
        <Typography mb={1}>Time remaining:</Typography>
        <Chip
          label={toClockFormat(testStartTime.getTime() - new Date().getTime())}
          variant="outlined"
        />
      </Stack>
    </>
  );
}
