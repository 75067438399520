import { Button, Typography } from '@mui/material';

interface IProps {
  onStartTest: () => void;
}

export default function WelcomeBackView(props: IProps) {
  const { onStartTest } = props;

  return (
    <>
      <Typography variant="h6" mb={2}>
        Welcome back!
      </Typography>
      <Typography mb={2}>Are you ready to take the test now?</Typography>
      <Button variant="outlined" onClick={onStartTest}>
        Let’s do it!
      </Button>
    </>
  );
}
