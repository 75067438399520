import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { AppMode } from 'types/AppMode';
import { UserState } from 'types/UserState';
import { useUserState } from './UserStateProvider';

interface IContext {
  currentMode: AppMode;
  practiceEndTime: Date;
  testStartTime: Date;
}

const AppModeContext = createContext<IContext | undefined>(undefined);

const PRACTISE_DURATION = 10 * 60 * 1000; // 10 minutes
const AWAY_DURATION = 60 * 60 * 1000; // 1 hour

function calculateState({ challengeStartTime, testCompletedTime }: UserState): IContext {
  const millisecondsSinceChallengeStartTime = new Date().getTime() - challengeStartTime;
  const practiceEndTime = new Date(challengeStartTime + PRACTISE_DURATION);
  const testStartTime = new Date(challengeStartTime + AWAY_DURATION);

  if (testCompletedTime > challengeStartTime) {
    return {
      currentMode: 'Home',
      practiceEndTime,
      testStartTime,
    };
  }

  if (millisecondsSinceChallengeStartTime < PRACTISE_DURATION) {
    return {
      currentMode: 'Practice',
      practiceEndTime,
      testStartTime,
    };
  }

  if (millisecondsSinceChallengeStartTime < AWAY_DURATION) {
    return {
      currentMode: 'Away',
      practiceEndTime,
      testStartTime,
    };
  }

  return {
    currentMode: 'Test',
    practiceEndTime,
    testStartTime,
  };
}

function AppModeProvider({ children }: PropsWithChildren) {
  const { userState } = useUserState();

  const [state, setState] = useState(calculateState(userState));
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>();

  useEffect(() => {
    if (intervalId !== undefined) {
      clearInterval(intervalId);
      setIntervalId(undefined);
    }
    setState(calculateState(userState));
  }, [userState.challengeStartTime]);

  useEffect(() => {
    // Don't recalculate state if we're in home mode
    if (state.currentMode !== 'Home' && intervalId === undefined) {
      setIntervalId(setInterval(() => setState(calculateState(userState)), 1000));
    }
  }, [state.currentMode, intervalId]);

  return <AppModeContext.Provider value={state}>{children}</AppModeContext.Provider>;
}

function useAppMode(): IContext {
  const appModeContext = useContext(AppModeContext);

  if (!appModeContext) {
    throw new Error('useAppMode must be used inside an AppModeProvider.');
  }

  return appModeContext;
}

export default AppModeProvider;
export { useAppMode };
