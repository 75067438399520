import { PropsWithChildren, createContext, useContext, useState } from 'react';
import { UserState } from 'types/UserState';

const USER_STATE_STORAGE_KEY = 'JellyLlamaDisco:UserState';

interface IContext {
  userState: UserState;
  updateUserState: (nextUserState: UserState) => void;
}

const UserStateContext = createContext<IContext | undefined>(undefined);

function UserStateProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const userStateString = localStorage.getItem(USER_STATE_STORAGE_KEY);
  let initialUserState: UserState;
  if (userStateString) {
    initialUserState = JSON.parse(userStateString);

    // Ensure stored state conforms to current expected model
    if (!initialUserState.currentAnswers) {
      initialUserState.currentAnswers = [];
    }
    if (initialUserState.maxNumberOfWords === undefined) {
      initialUserState.maxNumberOfWords = initialUserState.numberOfWords;
    }
  } else {
    initialUserState = {
      maxNumberOfWords: 3,
      challengeStartTime: new Date().getTime(),
      wordsStartIndex: 0,
      numberOfWords: 3,
      currentAnswers: [],
      testCompletedTime: 0,
      success: true,
    };
    localStorage.setItem(USER_STATE_STORAGE_KEY, JSON.stringify(initialUserState));
  }

  const [userState, setUserState] = useState(initialUserState);

  const updateUserState = (nextUserState: UserState) => {
    localStorage.setItem(USER_STATE_STORAGE_KEY, JSON.stringify(nextUserState));
    setUserState(nextUserState);
  };

  return (
    <UserStateContext.Provider value={{ userState, updateUserState }}>
      {children}
    </UserStateContext.Provider>
  );
}

function useUserState(): IContext {
  const userStateContext = useContext(UserStateContext);

  if (!userStateContext) {
    throw new Error('useUserState must be used inside an UserStateProvider.');
  }

  return userStateContext;
}

export default UserStateProvider;
export { useUserState };
