import { useUserState } from 'providers/UserStateProvider';
import { useState } from 'react';
import TestView from './TestView';
import WelcomeBackView from './WelcomeBackView';

export default function TestModeContainer() {
  const { userState } = useUserState();

  const [showTest, setShowTest] = useState(userState.currentAnswers.length > 0);

  return !showTest ? <WelcomeBackView onStartTest={() => setShowTest(true)} /> : <TestView />;
}
