export function getPartOfDay(now?: Date): string {
  const hour = (now ?? new Date()).getHours();
  if (hour < 12) {
    return 'morning';
  }
  if (hour < 18) {
    return 'afternoon';
  }
  return 'evening';
}

function toTwoDigitString(number: number): string {
  return number.toString().padStart(2, '0');
}

export function toClockFormat(milliseconds: number): string {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const seconds = totalSeconds % 60;

  const totalMinutes = Math.floor(totalSeconds / 60);
  const minutes = totalMinutes % 60;

  const totalHours = Math.floor(totalMinutes / 60);

  const hoursString = totalHours > 0 ? `${totalHours}:` : '';
  const minutesString = totalHours > 0 ? toTwoDigitString(minutes) : minutes.toString();
  const secondsString = toTwoDigitString(seconds);

  return `${hoursString}${minutesString}:${secondsString}`;
}

export function to12HourTime(date: Date): string {
  // Using 'en-US' as it capitalises AM and PM which looks nice
  const timeString = date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  return timeString;
}
