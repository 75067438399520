import { Box } from '@mui/material';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import AppBar from 'AppBar';
import AwayModeContainer from 'containers/Away/AwayModeContainer';
import HomeModeContainer from 'containers/Home/HomeModeContainer';
import PracticeModeContainer from 'containers/Practice/PracticeModeContainer';
import TestModeContainer from 'containers/Test/TestModeContainer';
import { useAppMode } from 'providers/AppModeProvider';

export default function App() {
  const { currentMode } = useAppMode();

  return (
    <Container maxWidth="xs">
      <AppBar />
      <Box component="main" pt={2}>
        <Toolbar />
        {currentMode === 'Home' && <HomeModeContainer />}
        {currentMode === 'Practice' && <PracticeModeContainer />}
        {currentMode === 'Away' && <AwayModeContainer />}
        {currentMode === 'Test' && <TestModeContainer />}
      </Box>
    </Container>
  );
}
