import { Alert, Stack } from '@mui/material';
import Test from 'components/Test';
import { useAppMode } from 'providers/AppModeProvider';
import { useUserState } from 'providers/UserStateProvider';
import { to12HourTime, toClockFormat } from 'utils/dateTimeUtils';

interface IProps {
  onFinishTest: () => void;
}

export default function PractiseTestView(props: IProps) {
  const { onFinishTest } = props;

  const { userState } = useUserState();
  const { wordsStartIndex, numberOfWords } = userState;

  const { practiceEndTime, testStartTime } = useAppMode();

  const practiceTimeRemaining = toClockFormat(practiceEndTime.getTime() - new Date().getTime());

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1} mb={1}>
        <Alert severity="warning">
          Practice mode ends in <b>{practiceTimeRemaining}</b>. You can test yourself for real any
          time after <b>{to12HourTime(testStartTime)}</b>.
        </Alert>
      </Stack>
      <Test startIndex={wordsStartIndex} numberOfWords={numberOfWords} onComplete={onFinishTest} />
    </>
  );
}
