import Test from 'components/Test';
import { useUserState } from 'providers/UserStateProvider';

export default function TestView() {
  const { userState, updateUserState } = useUserState();

  const handleAnswerSubmitted = (answer: string) => {
    userState.currentAnswers.push(answer);
    updateUserState(userState);
  };

  const handleComplete = (success: boolean) => {
    userState.testCompletedTime = new Date().getTime();
    userState.wordsStartIndex += userState.numberOfWords;
    userState.currentAnswers = [];
    userState.success = success;
    if (success && userState.numberOfWords === userState.maxNumberOfWords) {
      userState.maxNumberOfWords += 1;
    }
    updateUserState(userState);
  };

  return (
    <Test
      startIndex={userState.wordsStartIndex}
      numberOfWords={userState.numberOfWords}
      initialAnswers={userState.currentAnswers}
      onAnswerSubmitted={handleAnswerSubmitted}
      onComplete={handleComplete}
    />
  );
}
