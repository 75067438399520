import { Alert, Box, Button, Typography } from '@mui/material';
import GoodPartOfDayTitle from 'components/GoodPartOfDayTitle';
import WordsList from 'components/WordsList';
import { useAppMode } from 'providers/AppModeProvider';
import { useUserState } from 'providers/UserStateProvider';
import { to12HourTime, toClockFormat } from 'utils/dateTimeUtils';

interface IProps {
  onStartTest: () => void;
}

export default function NextWordsView(props: IProps) {
  const { onStartTest } = props;

  const { userState } = useUserState();
  const { wordsStartIndex, numberOfWords } = userState;

  const { practiceEndTime, testStartTime } = useAppMode();

  const practiceTimeRemaining = toClockFormat(practiceEndTime.getTime() - new Date().getTime());

  return (
    <>
      {wordsStartIndex === 0 && <GoodPartOfDayTitle />}

      <Typography>
        Your challenge is simply to memorise the following{' '}
        {numberOfWords > 1 ? `${numberOfWords} words (in any order)` : 'word'}:
      </Typography>

      <Box my={2}>
        <WordsList startIndex={wordsStartIndex} numberOfWords={numberOfWords} />
      </Box>

      <Box mb={2}>
        <Alert severity="warning">
          You have <b>{practiceTimeRemaining}</b> left to memorise and practise.
        </Alert>
      </Box>
      <Box display="flex" justifyContent="center" mb={2}>
        <Button variant="contained" onClick={onStartTest}>
          Practise now
        </Button>
      </Box>

      <Typography>
        Come back to test yourself any time after <b>{to12HourTime(testStartTime)}</b>. If you can
        remember all {numberOfWords} words, your next challenge will be harder!
      </Typography>
    </>
  );
}
