import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, rectSortingStrategy } from '@dnd-kit/sortable';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { WORDS } from 'data/WORDS';
import { useState } from 'react';
import WordsListItem from './WordsListItem';

interface IProps {
  startIndex: number;
  numberOfWords: number;
}

export default function WordsList(props: IProps) {
  const { startIndex, numberOfWords } = props;

  const [words, setWords] = useState(WORDS.slice(startIndex, startIndex + numberOfWords));
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over !== null && active.id !== over.id) {
      setWords((prevWords) => {
        const oldIndex = prevWords.indexOf(active.id as string);
        const newIndex = prevWords.indexOf(over.id as string);

        return arrayMove(prevWords, oldIndex, newIndex);
      });
    }
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={words} strategy={rectSortingStrategy}>
        <Grid2 container spacing={1}>
          {words.map((word) => (
            <WordsListItem key={word} word={word} />
          ))}
        </Grid2>
      </SortableContext>
    </DndContext>
  );
}
