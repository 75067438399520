import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Chip } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import './WordsListItem.css';

interface IProps {
  word: string;
}

export default function WordsListItem(props: IProps) {
  const { word } = props;

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: word });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Grid2 ref={setNodeRef} style={style} {...attributes} {...listeners} xs={4}>
      <Chip label={word} variant="filled" color="success" className="words-list-item" />
    </Grid2>
  );
}
