import { useState } from 'react';
import NextWordsView from './NextWordsView';
import PractiseTestView from './PractiseTestView';

export default function PracticeModeContainer() {
  const [showTest, setShowTest] = useState(false);

  return !showTest ? (
    <NextWordsView onStartTest={() => setShowTest(true)} />
  ) : (
    <PractiseTestView onFinishTest={() => setShowTest(false)} />
  );
}
